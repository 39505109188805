<template>
    <button id="scroll-up-btn" class="scroll-up-btn" @click="scrollToTop">
      <i class="arrow-up"></i>
    </button>
  
    <div :class="[theme, 'container']">
      <div class="content">
        <h1>
          <a href="/" class="homepage-link">{{ translatedText.title }}</a>
        </h1>
        <h4>{{ translatedText.subtitle1 }}</h4>
        <h4>{{ translatedText.subtitle2 }}</h4>
  
        <div v-if="showChangelog" class="changelog-box">
          <p v-html="changelogMessage"></p>
          <button class="close-changelog" @click="closeChangelog">X</button>
        </div>
  
        <!-- Filter and Settings controls -->
        <div class="rss-filter">
          <button class="filter-toggle" @click="toggleFilters">
            {{ showFilters ? translatedText.hideFilters : translatedText.showFilters }}
          </button>
          <button class="filter-toggle" @click="toggleSettings">
            {{ showSettings ? translatedText.hideSettings : translatedText.settings }}
          </button>
          <button class="filter-toggle" @click="refreshItems">
           {{ refreshButtonText }}
          </button>
  <!-- Contact Button -->
  <button class="filter-toggle" @click="showContactForm = true">
    {{translatedText.contact}}
  </button>
  
  <!-- Contact Form Modal -->
  <div v-if="showContactForm" class="contact-modal">
    <div class="contact-modal-content">
      <button class="close-modal" @click="showContactForm = false">X</button>
      <h2 v-if="!successMessage">{{ translatedText.contactUs }}</h2>
  
      <form @submit.prevent="submitForm" v-if="!successMessage">
        <label for="name">Name</label>
        <input type="text" id="name" v-model="name" required :placeholder="translatedText.contactName" />
  
        <label for="email">Email</label>
        <input type="email" id="email" v-model="email" required :placeholder="translatedText.contactEmail" />
  
        <label for="message">Message</label>
        <textarea id="message" v-model="message" required :placeholder="translatedText.contactMessage"></textarea>
  
        <button type="submit" class="submit-btn">{{translatedText.contactSubmit}}</button>
      </form>
  
      <div v-if="successMessage" class="thank-you-message">
        <h3>{{ successMessage }}</h3>
        <button class="close-modal" @click="showContactForm = false">X</button>
      </div>
    </div>
  </div>
  
          <div v-show="showFilters">
            <button class="filter-control" @click="selectAllFilters">{{ translatedText.selectAll }}</button>
            <button class="filter-control" @click="deselectAllFilters">{{ translatedText.deselectAll }}</button>
            <div
              v-for="source in rssSources"
              :key="source.name"
              class="rss-tag-filter"
              :class="{ selected: selectedFilters.includes(source.name) }"
              :style="{
                backgroundColor: selectedFilters.includes(source.name)
                  ? source.color
                  : theme === 'dark'
                  ? '#151719'
                  : '#ddd',
                color: selectedFilters.includes(source.name)
                  ? isBrightColor(source.color)
                    ? '#000'
                    : '#fff'
                  : theme === 'dark'
                  ? '#fff'
                  : '#000',
                fontWeight: 'bold',
              }"
              @click.prevent="toggleFilter(source.name)"
            >
              {{ source.name }}
            </div>
          </div>
  
          <!-- Settings dropdown -->
          <div v-show="showSettings" class="settings-dropdown">
            <label for="timeLimit">{{ translatedText.timeLimitLabel }}</label>
            <input type="number" id="timeLimit" v-model.number="timeLimit" min="0" placeholder="0" @input="saveTimeLimit" />
            <label for="timeLimit"> {{ translatedText.timeLimitInfo }}</label>
  
            <!-- Light/Dark mode toggle -->
            <div class="theme-toggle">
              <label for="themeToggle">{{ translatedText.themeToggleLabel }}</label>
              <div class="toggle-switch">
                <input type="checkbox" id="themeToggle" v-model="isDarkTheme" @change="toggleTheme" />
                <label for="themeToggle" class="toggle-slider"></label>
              </div>
            </div>
  
          </div>
        </div>

          
  
        <!-- RSS feed items -->
        <div class="rss-feed">
                    <div v-if="isLoading" class="loading-message">
  <p>Posts Loading...</p>
</div>
          <a
            v-for="(item, index) in filteredItems"
            :key="item.link"
            class="rss-item_news"
            :href="`${item.link}?ref=gamingnews.today`"
            target="_blank"
            :class="theme"
          >
            <h2 v-html="decodeHtml(item.title)"></h2>
            <!-- Display the image with skeleton loading -->
            <div class="image-wrapper">
              <div v-if="!loadingImages[index]" class="loading-container">
                <div class="loading-spinner"></div>
                <div class="skeleton-loader"></div>
              </div>
              <img
                v-if="item.image"
                :src="item.image"
                :alt="item.title"
                class="rss-image"
                @load="handleImageLoad(index)"
                @error="handleImageError(index)"
                v-show="loadingImages[index]"
              />
            </div>
            <p>{{ item.description }}</p>
            <div class="rss-footer">
              <span
                class="rss-tag"
                :style="{
                  backgroundColor: getSourceColor(item.game),
                  color: isBrightColor(getSourceColor(item.game)) ? '#000' : '#fff',
                  fontWeight: 'bold',
                  padding: '4px 10px',
                  borderRadius: '4px',
                }"
              >
                {{ item.game }}
              </span>
              <span class="separator"> - </span>
              <time>about {{ timeSince(item.pubDate) }}</time>
            </div>
          </a>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  export default {
    data() {
      return {
        rssSources: [
        { name: 'Deadlock', color: '#EFDEBB' }, 
        { name: 'Throne and Liberty', color: '#545295' },
        { name: 'ICARUS', color: '#EEAF2B' },
        { name: 'EA SPORTS FC™ 25', color: '#578419' },
        { name: 'Spectre Divide', color: '#FFCD1C' },
        { name: 'Valorant', color: '#FF4D5A' },
        { name: 'League of Legends', color: '#CAA763' },
        { name: 'CS2', color: '#1570E7' },
        { name: 'Fortnite', color: '#1570E7' },
        { name: 'Apex Legends', color: '#D42B29' }, 
        { name: 'BO6 & COD Warzone', color: '#FA6D09' },
        { name: 'Rainbow Six Siege', color: '#1D5FDF' },
        { name: 'Battlefield 2042', color: '#23FEE0' },
        { name: 'World of Warcraft', color: '#EED026' },
        { name: "No Man's Sky", color: '#DE2043' },
        { name: 'Dark and Darker', color: '#A28A3B' },
        { name: "PUBG: Battlegrounds", color: '#F6C833' },
        { name: "Dota 2", color: '#B92A1A' },
        { name: "Diablo 3", color: '#E94330' },
        { name: "Rocket League", color: '#147DCE' },
        { name: "Hearthstone", color: '#C38418' },
        { name: "Destiny 2", color: '#728EA6' },
        { name: "Smite", color: '#AA7A18' },
        { name: "For Honor", color: '#D8CBA9' },
        { name: "Fallout 76", color: '#DEB833' },
        { name: "The Division 2", color: '#FC6C0F' },
        { name: "Grounded", color: '#8C951C' },
        { name: "Fall Guys", color: '#3BD8E1' },
        { name: "Valheim", color: '#8D0A04' },
        { name: "Forza Horizon 5", color: '#FF1DA3' },
        { name: "Hell Let Loose", color: '#345656' },
        { name: "Elden Ring", color: '#FFF296' },
        { name: "Warhammer 40,000: Space Marine 2", color: '#1F55AC' },
        { name: "Stellaris", color: '#F8C484' },
        { name: "Once Human", color: '#2BC3C1' },
        { name: "The First Descendant", color: '#A3CBED' },
        { name: "XDefiant", color: '#D4B61C' },
        { name: "Gray Zone Warfare", color: '#314352' },
        { name: "Tekken 8", color: '#F6214D' },
        { name: "Manor Lords", color: '#BA756A' },
        { name: "Hades II", color: '#73DA8E' },
        { name: "Skull and Bones", color: '#F2B31D' },
        { name: "Helldivers 2", color: '#FEEA1E' },
        { name: "Palworld", color: '#0091D3' },
        { name: "Path of Exile 2", color: '#65191B' },
        { name: "Sins of a Solar Empire II", color: '#33EADB' },
        { name: "S.T.A.L.K.E.R. 2", color: '#9C2B1B' },
        { name: "Brighter Shores", color: '#2A4962' },
        { name: "Smite 2", color: '#AA7A18' },            
        { name: "Garry's Mod", color: '#1294F1' },
        { name: "Satisfactory", color: '#DC8428' },
        { name: "Heroes of the Storm", color: '#1172FF' },
        { name: "New World", color: '#40405A' },
        { name: "The Outlast Trials", color: '#929572' },
        { name: "7 Days to Die", color: '#F60007' },
        { name: "Squad", color: '#FFC309' },
        { name: "ARK: Survival Ascended", color: '#397AA1' },
        { name: "Cities: Skylines II", color: '#2892FF' },
        { name: "Microsoft Flight Simulator", color: '#1895CE' },
        { name: "Delta Force", color: '#26F69A' },
        { name: "SUPERVIVE", color: '#B837EB' },
        { name: "Overwatch 2", color: '#EE6A20' },
        { name: "MultiVersus", color: '#FE1612' },
        { name: "Marvel Rivals", color: '#FEE034' },
        ],
        rssItems: [],
        searchQuery: '',
        isFetching: false,
        selectedFilters: [],
        showFilters: false,
        showSettings: false,
        currentPage: 1,
        isNotificationEnabled: false, // Stores the user's preference for notifications
        notificationStorageKey: '-XPGAINED-notifications',
        itemsPerPage: 16,
        isLoading: false,
        hasMorePosts: true,
        placeholderImageUrl: 'https://i.imgur.com/HlnOAvp.png',
        storageKey: '-XPGAINED-patchnotes',
        timeLimit: 0,
        showContactForm: false,  // For toggling the contact form popup
        name: "",
        email: "",
        message: "",
        successMessage: "",  // Success message after form submission
        timeLimitStorageKey: '-XPGAINED-timeLimit-patchnotes',
        theme: 'dark',
        isRefreshed: false,
        isDarkTheme: true,
        themeStorageKey: '-XPGAINED-theme',
        loadingImages: [],
        showChangelog: false,
        changelogMessage: `
          <h3 style="margin: -10px 0 10px 0; text-decoration: underline;">11th December</h3>
          <span style="color: green;">Added: 15 New Games</span>
        `,
        selectedLanguage: 'en',
        translatedText: {},
        languageOptions: {
          en: {
            title: 'Gaming Patch Notes',
            subtitle1: 'Stay updated with the latest gaming patch notes.',
            subtitle2: 'Make sure you never miss an update. Are we missing a game? Let us know by clicking the contact button.',
            settings: 'Settings',
            contact: 'Contact',
            contactUs: 'Contact Us',
            contactName: 'Your Name',
            contactEmail: 'Your Email',
            contactMessage: 'Your Message',
            contactSubmit: 'Submit',
            contactSuccess: 'Thank you for your message! We will get back to you when we can.',
            showFilters: 'Show Filters',
            hideFilters: 'Hide Filters',
            hideSettings: 'Hide Settings',
            refresh: 'Refresh',
            searchPlaceholder: 'Search the latest news...',
            selectAll: 'Select All',
            deselectAll: 'Deselect All',
            timeLimitLabel: 'Show posts from the past',
            timeLimitInfo: ' (hours) - Set to 0 to disable.',
            themeToggleLabel: 'Light/Dark:',
            languageLabel: 'Language:',
            feedsRefreshed: 'Posts Refreshed!',
            english: 'English',
            german: 'German',
            french: 'French',
            spanish: 'Spanish',
            timeAgo: {
           years: 'years ago',
           year: '1 year ago',
           months: 'months ago',
           month: '1 month ago',
           days: 'days ago',
           day: '1 day ago',
           hours: 'hours ago',
           hour: '1 hour ago',
           minutes: 'minutes ago',
           minute: '1 minute ago',
           seconds: 'seconds ago'
      },
          },
          de: {
          title: 'Videospiel-Nachrichten Heute',
          subtitle1: 'Bleib mit den neuesten Nachrichten aus der Welt der Videospiele von Top-Quellen auf dem Laufenden.',
          subtitle2: 'Entdecke Spielveröffentlichungen, Branchenupdates und Trends, um dein Spielerlebnis zu verbessern.',
          settings: 'Einstellungen',
          contact: 'Kontakt',
          contactUs: 'Kontaktieren Sie uns',
          contactName: 'Ihr Name',
          contactEmail: 'Ihre E-Mail',
          contactMessage: 'Ihre Nachricht',
          contactSubmit: 'Einreichen',
          contactSuccess: 'Vielen Dank für Ihre Nachricht! Wir werden uns bei Ihnen melden, sobald wir können.',
          showFilters: 'Filter anzeigen',
          hideFilters: 'Filter ausblenden',
          hideSettings: 'Einstellungen ausblenden',
          refresh: 'Vernieuwen',
          searchPlaceholder: 'Nach den neuesten Nachrichten suchen...',
          selectAll: 'Alle auswählen',
          deselectAll: 'Alle abwählen',
          timeLimitLabel: 'Beiträge der letzten anzeigen',
          timeLimitInfo: ' (Stunden) - Auf 0 setzen, um zu deaktivieren.',
          themeToggleLabel: 'Hell/Dunkel:',
          languageLabel: 'Sprache:',
          feedsRefreshed: 'Beiträge aktualisiert!',
          english: 'Englisch',
          german: 'Deutsch',
          french: 'Französisch',
          spanish: 'Spanisch',
          timeAgo: {
           years: 'vor Jahren',
           year: 'vor 1 Jahr',
           months: 'vor Monaten',
           month: 'vor 1 Monat',
           days: 'vor Tagen',
           day: 'vor 1 Tag',
           hours: 'vor Stunden',
           hour: 'vor 1 Stunde',
           minutes: 'vor Minuten',
           minute: 'vor 1 Minute',
           seconds: 'vor Sekunden'
      },
        },
        fr: {
    title: "Actualités des Jeux Vidéo Aujourd'hui",
    subtitle1: "Restez à jour avec les dernières nouvelles des jeux vidéo des meilleures sources.",
    subtitle2: "Découvrez les sorties de jeux, les mises à jour de l'industrie et les tendances pour améliorer votre expérience de jeu.",
    settings: 'Paramètres',
    contact: 'Contact',
    contactUs: 'Contactez-nous',
    contactName: 'Votre nom',
    contactEmail: 'Votre email',
    contactMessage: 'Votre message',
    contactSubmit: 'Soumettre',
    contactSuccess: 'Merci pour votre message ! Nous vous répondrons dès que possible.',
    showFilters: 'Afficher les Filtres',
    hideFilters: 'Masquer les Filtres',
    hideSettings: 'Masquer les Paramètres',
    refresh: 'Actualiser',
    searchPlaceholder: 'Rechercher les dernières nouvelles...',
    selectAll: 'Tout sélectionner',
    deselectAll: 'Tout désélectionner',
    timeLimitLabel: 'Afficher les publications des dernières',
    timeLimitInfo: ' (heures) - Mettez à 0 pour désactiver.',
    themeToggleLabel: 'Clair/Sombre:',
    languageLabel: 'Langue:',
    feedsRefreshed: 'Messages actualisés !',
    english: 'Anglais',
    german: 'Allemande',
    french: 'Français',
    spanish: 'Espagnol',
    timeAgo: {
      years: 'il y a ans',
      year: 'il y a 1 an',
      months: 'il y a mois',
      month: 'il y a 1 mois',
      days: 'il y a jours',
      day: 'il y a 1 jour',
      hours: 'il y a heures',
      hour: 'il y a 1 heure',
      minutes: 'il y a minutes',
      minute: 'il y a 1 minute',
      seconds: 'il y a secondes'
    }
  },
  es: {
    title: "Noticias de Videojuegos Hoy",
    subtitle1: "Mantente al día con las últimas noticias de videojuegos de las mejores fuentes.",
    subtitle2: "Descubre lanzamientos de juegos, actualizaciones de la industria y tendencias para mejorar tu experiencia de juego.",
    settings: 'Configuración',
    contact: 'Contacto',
    contactUs: 'Contáctanos',
    contactName: 'Tu nombre',
    contactEmail: 'Tu correo electrónico',
    contactMessage: 'Tu mensaje',
    contactSubmit: 'Entregar',
    contactSuccess: '¡Gracias por tu mensaje! Nos comunicaremos con usted cuando podamos.',
    showFilters: 'Mostrar Filtros',
    hideFilters: 'Ocultar Filtros',
    hideSettings: 'Ocultar Configuración',
    refresh: 'Actualizar',
    searchPlaceholder: 'Buscar las últimas noticias...',
    selectAll: 'Seleccionar todo',
    deselectAll: 'Deseleccionar todo',
    timeLimitLabel: 'Mostrar publicaciones de las últimas',
    timeLimitInfo: ' (horas) - Poner en 0 para desactivar.',
    themeToggleLabel: 'Claro/Oscuro:',
    languageLabel: 'Idioma:',
    feedsRefreshed: 'Publicaciones actualizadas!',
    english: 'Inglés',
    german: 'Alemán',
    french: 'Francés',
    spanish: 'Español',
    timeAgo: {
      years: 'hace años',
      year: 'hace 1 año',
      months: 'hace meses',
      month: 'hace 1 mes',
      days: 'hace días',
      day: 'hace 1 día',
      hours: 'hace horas',
      hour: 'hace 1 hora',
      minutes: 'hace minutos',
      minute: 'hace 1 minuto',
      seconds: 'hace segundos'
    }
  },
        },
      };
    },
    computed: {
      filteredItems() {
    let items = this.rssItems;
  
    // No selected filters and no search query
    if (this.selectedFilters.length === 0 && this.searchQuery.trim() === '') {
      return []; // No items to display
    }
  
  
    // Filter by search query for title and excerpt
    if (this.searchQuery.trim() !== '') {
      const query = this.searchQuery.toLowerCase();
      items = items.filter(
        (item) =>
          item.title.toLowerCase().includes(query) ||
          item.description.toLowerCase().includes(query) ||
          (item.game && item.game.toLowerCase().includes(query))
      );
    }
  
    // Filter by selected sources
    if (this.selectedFilters.length > 0) {
      items = items.filter((item) => this.selectedFilters.includes(item.game));
    }
  
    // Filter out specific items with keywords like "Monopoly GO"
    items = items.filter((item) => !item.title.toLowerCase().includes('monopoly go'));
  
    // Apply time limit if active
    if (this.timeLimit > 0) {
      const currentTime = new Date();
      items = items.filter((item) => {
        const itemTime = new Date(item.pubDate);
        const timeDiff = (currentTime - itemTime) / 3600000; // Difference in hours
        return timeDiff <= this.timeLimit;
      });
    }
  
    // Exclude items with future publication dates
    items = items.filter((item) => new Date(item.pubDate) <= new Date());
  
    // Pagination
    return items.slice(0, this.currentPage * this.itemsPerPage);
  },
  
      refreshButtonText() {
        return this.isRefreshed ? this.translatedText.feedsRefreshed : this.translatedText.refresh;
      },
    },
    methods: {
      getFlag(language) {
      const flags = {
        en: 'https://flagcdn.com/w20/gb.png', // UK Flag for English
        de: 'https://flagcdn.com/w20/de.png', // Spain Flag for German
        fr: 'https://flagcdn.com/w20/fr.png', // France Flag for French
        es: 'https://flagcdn.com/w20/es.png', // Spanish Flag for Spanish
      };
      return flags[language] || flags['en'];
    },
    submitForm() {
      fetch("https://www.formbackend.com/f/6336af5133e6f762", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: this.name,
          email: this.email,
          message: this.message,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Something went wrong");
          }
          return response.json();
        })
        .then(() => {
          this.successMessage = "Thank you for your message!\nWe will get back to you when we can.";
          this.name = "";
          this.email = "";
          this.message = "";
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
        });
    },
      changeLanguage() {
      this.translatedText = this.languageOptions[this.selectedLanguage];
      this.fetchAndDisplayFeeds(); // Fetch and update feed items when language changes
    },
    refreshItems() {
        this.fetchAndDisplayFeeds(); // Call the method to refresh/reload items
  
        // Temporarily change the button text
        this.isRefreshed = true;
        setTimeout(() => {
          this.isRefreshed = false;
        }, 3000); // Reset the text after 3 seconds
      },
      handleImageLoad(index) {
        this.loadingImages[index] = true; // Mark the image as loaded
      },
      handleImageError(index) {
        this.loadingImages[index] = false; // Keep skeleton in case of error
      },
      toggleFilters() {
        this.showFilters = !this.showFilters;
      },
      toggleSettings() {
        this.showSettings = !this.showSettings;
      },
      saveTimeLimit() {
        localStorage.setItem(this.timeLimitStorageKey, this.timeLimit);
        this.currentPage = 1;
      },
      closeChangelog() {
        this.showChangelog = false;
        localStorage.setItem('PatchNoteschangelogDismissed', 'true');
        localStorage.setItem('PatchNoteschangelogMessage', this.changelogMessage);
      },
      checkChangelogStatus() {
        const changelogDismissed = localStorage.getItem('PatchNoteschangelogDismissed');
        const storedMessage = localStorage.getItem('PatchNoteschangelogMessage');
  
        if (!changelogDismissed || storedMessage !== this.changelogMessage) {
          this.showChangelog = true;
        }
      },
      getTranslatedText(item, field) {
      const languageKey =
        this.selectedLanguage === 'de'
          ? 'German'
          : this.selectedLanguage === 'fr'
          ? 'French'
          : this.selectedLanguage === 'es'
          ? 'Spanish'
          : '';
      if (languageKey) {
        return item[`${field}${languageKey}`] || item[field]; // Fallback to default field if translation doesn't exist
      }
      return item[field]; // Default to English if no languageKey is set
    },
      scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      },
      toggleTheme() {
        this.theme = this.isDarkTheme ? 'dark' : 'light';
        localStorage.setItem(this.themeStorageKey, this.theme);
      },
      toggleFilter(sourceName) {
    const index = this.selectedFilters.indexOf(sourceName);
    if (index > -1) {
      // If sourceName is already selected, remove it
      this.selectedFilters.splice(index, 1);
    } else {
      // Otherwise, add it to the selected filters
      this.selectedFilters.push(sourceName);
    }
    this.saveSelectedFilters();
  },
      selectAllFilters() {
        this.selectedFilters = this.rssSources.map((source) => source.name);
        this.resetFeed();
      },
      deselectAllFilters() {
        this.selectedFilters = [];
        this.resetFeed();
      },
      filterFeed() {
        this.currentPage = 1; // Reset to first page when search is performed
      },
      resetFeed() {
        this.currentPage = 1;
        this.hasMorePosts = true; // Reset the flag when resetting the feed
        this.saveSelectedFilters();
      },
      saveSelectedFilters() {
        localStorage.setItem(this.storageKey, JSON.stringify(this.selectedFilters));
      },
      loadSelectedFilters() {
        const savedFilters = localStorage.getItem(this.storageKey);
        if (savedFilters) {
          this.selectedFilters = JSON.parse(savedFilters);
        } else {
          this.selectedFilters = this.rssSources.map((source) => source.name);
        }
  
        // Load the time limit from localStorage
        const savedTimeLimit = localStorage.getItem(this.timeLimitStorageKey);
        if (savedTimeLimit !== null) {
          this.timeLimit = Number(savedTimeLimit);
        }
  
        // Load the theme from localStorage
        const savedTheme = localStorage.getItem(this.themeStorageKey);
        if (savedTheme) {
          this.theme = savedTheme;
          this.isDarkTheme = savedTheme === 'dark';
        }
  
        // Load the selected language from localStorage
        const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage) {
          this.selectedLanguage = savedLanguage;
        }
  
        // Set the translated text based on the selected language
        this.changeLanguage();
      },
      fetchAndDisplayFeeds() {
      if (this.isLoading) return;
  
      this.isLoading = true;
  
      // Fetch the patch notes data
      fetch('/wiuh28h34t93h49th39.json')
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          const allItems = data
            .filter((item) => !isNaN(Date.parse(item.pubDate))) // Filter out invalid dates
            .map((item) => {
              return {
                game: item.game,
                title: item.title,
                link: item.link,
                description: item.description,
                image: item.image || this.placeholderImageUrl,
                pubDate: new Date(item.pubDate),
              };
            })
            .sort((a, b) => Date.parse(b.pubDate) - Date.parse(a.pubDate));
  
          this.rssItems = allItems;
          this.isLoading = false;
        })
        .catch((error) => {
          console.error('Error loading patch notes:', error);
          this.isLoading = false;
        });
    },
      getSourceColor(sourceName) {
        const source = this.rssSources.find((s) => s.name === sourceName);
        return source ? source.color : '#000';
      },
      truncateExcerpt(text, limit = 200) {
        if (text.length <= limit) return text;
        return text.substr(0, limit) + '...';
      },
      timeSince(date) {
    const currentTime = new Date();
    const seconds = Math.floor((currentTime - date) / 1000);
    const translatedTimeAgo = this.translatedText.timeAgo;
  
    let interval = Math.floor(seconds / 31536000);
    if (interval > 1) return `${interval} ${translatedTimeAgo.years}`;
    if (interval === 1) return `${translatedTimeAgo.year}`;
  
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) return `${interval} ${translatedTimeAgo.months}`;
    if (interval === 1) return `${translatedTimeAgo.month}`;
  
    interval = Math.floor(seconds / 86400);
    if (interval > 1) return `${interval} ${translatedTimeAgo.days}`;
    if (interval === 1) return `${translatedTimeAgo.day}`;
  
    interval = Math.floor(seconds / 3600);
    if (interval > 1) return `${interval} ${translatedTimeAgo.hours}`;
    if (interval === 1) return `${translatedTimeAgo.hour}`;
  
    interval = Math.floor(seconds / 60);
    if (interval > 1) return `${interval} ${translatedTimeAgo.minutes}`;
    if (interval === 1) return `${translatedTimeAgo.minute}`;
  
    return `${Math.floor(seconds)} ${translatedTimeAgo.seconds}`;
  },
      isBrightColor(color) {
        const c = color.substring(1);
        const rgb = parseInt(c, 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >> 8) & 0xff;
        const b = (rgb >> 0) & 0xff;
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;
        return brightness > 123;
      },
      handleScroll() {
        const scrollPosition = window.innerHeight + window.scrollY;
        const threshold = document.documentElement.scrollHeight - 300;
  
        if (scrollPosition >= threshold && !this.isLoading) {
          this.currentPage++;
          this.fetchAndDisplayFeeds();
        }
      },
      decodeHtml(html) {
        const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
      },
    },
    mounted() {
      this.loadSelectedFilters();
    this.fetchAndDisplayFeeds();
    this.checkChangelogStatus();
  
      // Sort RSS sources alphabetically
      this.rssSources.sort((a, b) => a.name.localeCompare(b.name));
  
      // Add scroll event listener for infinite scrolling
      window.addEventListener('scroll', this.handleScroll);
  
      // Preload images using IntersectionObserver (optional code for images)
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const img = entry.target;
              img.src = img.dataset.src; // Set the real image source
              observer.unobserve(img); // Stop observing once the image is loaded
            }
          });
        },
        { rootMargin: '100px' } // Preload when the image is 100px away from the viewport
      );
  
      // Observe all the images that have a data-src attribute (lazy-load)
      this.$nextTick(() => {
        document.querySelectorAll('img[data-src]').forEach((img) => {
          observer.observe(img);
        });
      });
    },
    beforeUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
    },
    watch: {
      selectedLanguage(newLang) {
      this.changeLanguage(); // When the language changes, update the translations and fetch new items
      localStorage.setItem('selectedLanguage', newLang);
    },
    },
  };
  </script>
  
  <style>
  /* Import the Inter font */
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;600;700&display=swap');
  
  
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    overscroll-behavior: none;
  }
  
  .language-selector {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-family: 'Inter', sans-serif;
  }
  
  .custom-select {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .light .custom-select select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 8px 10px;
    border-radius: 5px;
    border: 1px solid #333;
    font-family: 'Inter', sans-serif;
    background-color: #ffffff;
    color: #000000;
    width: 110px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .dark .custom-select select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 8px 10px;
    border-radius: 5px;
    border: 1px solid #333;
    font-family: 'Inter', sans-serif;
    background-color: #151719;
    color: #fff;
    width: 110px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .custom-select select:focus {
    outline: none;
    border-color: #b541e9;
  }
  
  .custom-select .flag {
    position: absolute;
    right: 10px;
    pointer-events: none; /* Keep the flag non-interactive */
  }
  
  .flag-img {
    width: 20px;
    height: 14px;
    display: block;
  }
  
  
  .dark {
    background-color: #000;
    color: #fff;
  }
  
  .light {
    background-color: #f9f9f9;
    color: #000;
  }
  
  .container {
    width: 100%;
    min-height: 100vh;
  }
  
  .content {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    border-top: 1px solid;
    border-color: #BC4EDA;
  }
  
  .thank-you-message {
    text-align: center;
    color: #000; /* Set the text color to black */
  }
  
  .thank-you-message h3 {
    margin-top: 20px;
    font-size: 16px;
    color: #000; /* Ensures the thank you message is in black */
  }
  
  
  h6, h4 {
    padding-top: 20px;
    margin: 0;
    text-align: center;
  }
  
  .rss-filter {
    top: 0;
    z-index: 1000;
    background-color: #151719; /* Dark background to wrap around filter content */
    padding: 10px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap; /* Allow the filters and dropdown to stack */
    gap: 10px; /* Increased gap to avoid clutter */
    margin-bottom: 10px;
    margin-top: 25px;
    border-radius: 5px; /* Round corners for the filter background */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow */
    border: 1px solid #393939;
  }
  
  
  .filter-toggle {
    padding: 7px 10px;
    border: none;
    border-radius: 5px;
    background-color: #151719;
    color: #fff;
    cursor: pointer;
    border: 1px solid #393939;
    font-size: 16px;
    margin-right: 5px; /* Add some space between filter and settings buttons */
  }
  
  .filter-toggle:hover {
    background-color: #454545;
  }
  
  .filter-control {
    margin-right: 10px;
    margin-top: 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    background-color: #333;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    display: block;
    margin-bottom: 10px;
  }
  
  .rss-tag-filter {
    display: inline-block;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 15px; /* Rounded corners for wrapping effect */
    margin-right: 10px;
    margin-top: 5px;
    cursor: pointer;
    border: 1px solid #333;
    transition: background-color 0.3s, border-color 0.3s, color 0.3s;
    background-color: transparent; /* Transparent background by default */
  }
  .contact-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .contact-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Darken background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .contact-modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 500px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .contact-modal h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: black;
  }
  
  .contact-modal label {
    display: block;
    font-weight: 600;
    margin-bottom: 5px;
  }
  
  .contact-modal input,
  .contact-modal textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
  }
  
  .contact-modal textarea {
    height: 100px;
    resize: none;
  }
  
  .submit-btn {
    width: 100%;
    padding: 10px;
    background-color: #8e44ad;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-btn:hover {
    background-color: #7a39a0;
  }
  
  .close-modal {
    background: none;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    cursor: pointer;
  }
  
  .thank-you-message {
    text-align: center;
  }
  
  
  
  .light .rss-tag-filter {
    background-color: #f0f0f0; /* Light background for light mode */
    color: #000; /* Text color for light mode */
    border-color: #ccc; /* Adjust border for light mode */
  }
  
  /* Styles for dark theme */
  .dark .rss-tag-filter {
    background-color: #151719; /* Dark background for dark mode */
    color: #fff; /* Text color for dark mode */
    border-color: #333; /* Adjust border for dark mode */
  }
  
  .light .rss-filter {
    background-color: #F9F9F9; /* Light background for light mode */
    color: #000; /* Text color for light mode */
    border-color: #ccc; /* Adjust border for light mode */
  }
  
  .light .filter-toggle {
    background-color: #F9F9F9; /* Light background for light mode */
    color: #000; /* Text color for light mode */
    border-color: #ccc; /* Adjust border for light mode */
  }
  
  .dark .filter-toggle {
    background-color: #151719; /* Dark background for dark mode */
    color: #fff; /* Text color for dark mode */
    border-color: #333; /* Adjust border for dark mode */
  }
  
  .light .search-bar {
    background-color: #F9F9F9; /* Light background for light mode */
    color: #000; /* Text color for light mode */
    border-color: #ccc; /* Adjust border for light mode */
  }
  
  .dark .search-bar {
    background-color: #151719; /* Dark background for dark mode */
    color: #fff; /* Text color for dark mode */
    border-color: #333; /* Adjust border for dark mode */
  }
  
  .light .settings-dropdown input {
    background-color: #F9F9F9; /* Light background for light mode */
    color: #000; /* Text color for light mode */
    border-color: #ccc; /* Adjust border for light mode */
  }
  
  .dark .settings-dropdown input {
    background-color: #151719; /* Dark background for dark mode */
    color: #fff; /* Text color for dark mode */
    border-color: #333; /* Adjust border for dark mode */
  }
  
  .light .filter-control {
    color: #000000; /* Text color for light mode */
    background-color: #ffffff;
    border-color: #ccc; /* Adjust border for light mode */
    border: 1px solid #333;
  }
  
  .dark .filter-control {
    color: #fff; /* Text color for dark mode */
    border: 1px solid #333;
  }
  
  .rss-tag-filter.selected {
    border-color: #151719;
  }
  
  .rss-tag-filter:hover {
    border-color: #555;
  }
  
  .settings-dropdown {
    width: 100%; /* Make the settings dropdown full width */
    padding: 10px;
    border-radius: 5px;
    margin-top: -5px;
    margin-bottom: -25px;
  }
  
  .settings-dropdown label {
    margin-right: 10px;
  }
  
  .homepage-link {
    text-decoration: none; /* Remove the underline */
    color: inherit; /* Inherit the text color from the parent */
  }
  
  .homepage-link:hover {
    text-decoration: underline; /* Optional: Add an underline on hover if desired */
  }
  
  .settings-dropdown input {
    width: 40px;
    padding: 5px;
    border: 1px solid #555;
    border-radius: 3px;
    margin-left: -5px;
    background-color: #151719;
    color: #fff;
  }
  
  .settings-dropdown button {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    background-color: #444;
    color: #fff;
    cursor: pointer;
  }
  
  /* Toggle Switch Styles */
  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px; /* Width of the toggle */
    height: 24px; /* Height of the toggle */
    margin-left: 10px; /* Space between label and toggle */
  }
  
  h1 {
    font-size: 2.5rem; /* Adjust font size */
    text-transform: uppercase; /* Make the text uppercase */
    letter-spacing: 2px; /* Adjust letter spacing */
    color: #8e44ad; /* Base color */
    background: linear-gradient(to right, #8e44ad, #e056fd); /* Subtle purple to pink gradient */
    -webkit-background-clip: text; /* Clip the background to text */
    -webkit-text-fill-color: transparent; /* Make the fill color transparent */
    margin: 0;
    text-align: center;
    padding: 5px 20px; /* Add padding for spacing */
    border-radius: 5px; /* Slightly round the corners */
  }
  
  
  .toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .toggle-slider {
    position: absolute;
    cursor: pointer;
    top: -14px;
    left: -15px;
    right: 4px;
    bottom: 14px;
    background-color: #ccc; /* Default background color */
    transition: 0.4s; /* Smooth transition */
    border-radius: 24px; /* Rounded corners for the slider */
  }
  
  .toggle-slider:before {
    position: absolute;
    content: "";
    height: 20px; /* Height of the circle */
    width: 20px; /* Width of the circle */
    left: 2px; /* Spacing inside the toggle switch */
    bottom: 2px;
    background-color: white; /* Color of the circle */
    transition: 0.4s; /* Smooth transition */
    border-radius: 50%; /* Make the circle rounded */
  }
  
  input:checked + .toggle-slider {
    background-color: #B541E9; /* Background color when checked */
  }
  
  input:checked + .toggle-slider:before {
    transform: translateX(26px); /* Move the circle to the right when checked */
  }
  
  .rss-feed {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    padding-bottom: 60px;
  }
  
  .rss-item_news {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Space out content */
    border: 1px solid #393939;
    padding: 10px;
    border-radius: 5px;
    background: #151719;
    text-decoration: none;
    transition: border-color 0.3s;
    overflow: hidden;
    height: auto;
  }
  
  .rss-item_news .rss-content {
    flex-grow: 1; /* Allow the content to grow */
  }
  
  .rss-item_news .rss-footer {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .rss-item_news.dark {
    background-color: #151719;
  }
  
  .rss-item_news.light {
    background-color: #fff;
    color: #000;
    border: 1px solid #ccc;
  }
  
  .rss-item_news:hover {
    border-color: #B541E9;
  }
  
  .rss-item_news img {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
    max-height: 160px;
    object-fit: cover;
    margin-left: auto;
    margin-right: auto;
  }
  
  .rss-item_news h2 {
    font-size: 18px;
    margin: 5px 0;
    margin-bottom: 15px;
  }
  
  .theme-toggle {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .rss-item_news p {
    font-size: 13px;
    margin-top: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: block;
    margin-bottom: 10px; /* Add space between content */
    flex-grow: 1; /* Allow content to grow and push the meta section down */
  }
  
  /* Separator styling */
  .separator {
    margin: 2px 5px; /* Space between tag and time */
  }
  
  .rss-item_news time {
    font-size: 12px;
    color: #999;
    display: inline-block; /* Inline-block to ensure proper flex alignment */
    margin-top: 1px;
  }
  
  .rss-tag {
    display: inline-block;
    padding: 2px 10px;
    font-size: 12px;
    color: #fff;
    border-radius: 3px;
    margin: 0; /* Remove unnecessary margins */
  }
  
  .loading {
    text-align: center;
    padding: 10px;
    color: #666;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .loading-indicator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: #999;
  }
    
    .toggle-slider {
    position: absolute;
    cursor: pointer;
    top: -14px;
    left: -15px;
    right: 4px;
    bottom: 14px;
    background-color: #ccc; /* Default background color */
    transition: 0.4s; /* Smooth transition */
    border-radius: 24px; /* Rounded corners for the slider */
  }
  
  .rss-item_news.dark {
    background-color: #151719;
  }
  
  .rss-item_news.light {
    background-color: #fff;
    color: #000;
    border: 1px solid #ccc;
  }
  
  .refresh-message {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #333;
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 14px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    opacity: 0.9;
    transition: opacity 0.5s ease-in-out;
  }
  
  
  #load-more-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .scroll-up-btn {
    all: unset;
    position: fixed;
    bottom: 25px;
    left: 30px;
    height: 50px;
    width: 50px;
    background-color: #F9F9F9;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    z-index: 9999; /* Set a high z-index to keep the button on top */
  }
  
  .scroll-up-btn:hover {
    background-color: #e0e0e0; /* Change color on hover */
    transform: scale(1.1); /* Slightly enlarge on hover */
  }
  
  .scroll-up-btn:active {
    background-color: #d0d0d0; /* Change color when button is clicked */
  }
  
  .scroll-up-btn .arrow-up {
    width: 15px;
    height: 15px;
    border-left: 4px solid #000000;
    border-bottom: 4px solid #000000;
    transform: rotate(135deg);
    position: relative;
    top: 5px; /* Adjust this value to fine-tune the vertical position */
    pointer-events: none;
  }
  
  .image-wrapper {
    display: flex;
    justify-content: center; /* Horizontally center */
    align-items: center; /* Vertically center */
    width: 100%;
    height: 160px; /* Ensure the height is fixed or set accordingly */
  }
  
  .skeleton-loader {
    width: 100%;
    height: 100%;
    background-color: #dcdcdc; /* Less bright background */
    animation: pulse 1.5s ease-in-out infinite;
    border-radius: 4px;
  }
  
  .loading-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 24px;
    height: 24px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-left-color: #333;
    animation: spin 1s linear infinite;
    transform: translate(-50%, -50%); /* Ensures it is centered */
  }
  
  @keyframes pulse {
    0% {
      background-color: #151719;
    }
    50% {
      background-color: #151719;
    }
    100% {
      background-color: #151719;
    }
  }
  
  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  
  
  .rss-image {
    max-width: 100%;
    max-height: 160px;
    object-fit: cover;
    border-radius: 4px;
    display: block;
  }
  
  .search-bar {
    margin-left: auto; /* This keeps the search bar pushed to the far right */
    padding: 9px 10px;
    border: 1px solid #333;
    border-radius: 5px;
    background-color: #151719;
    color: #fff;
    font-size: 14px;
    width: auto; /* Allow the search bar to resize based on available space */
    flex-grow: 1; /* Allow the search bar to expand and fill available space */
    max-width: 100%; /* Ensure it doesn't overflow */
  }
  
  .changelog-box {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #f8f9fa;
    border: 1px solid #333;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 300px;
    color: #000;
    font-size: 14px;
  }
  
  .close-changelog {
    background: none;
    border: none;
    color: #333;
    font-weight: bold;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }

.loading-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-weight: bold;
  color: #666;
}

.dark .loading-message {
  color: #fff;
}


@media (max-width: 768px) {

  .loading-message {
  top: 111%;
}

  
    .scroll-up-btn {
    position: fixed;
    bottom: 25px;
    left: 30px;
    height: 50px;
    width: 50px;
    background-color: #F9F9F9;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    z-index: 999; /* Make sure the scroll-up button is above everything else */
  }
  
    .image-wrapper {
    position: relative;
    width: 100%;
    height: 160px; /* Ensure the height is fixed */
  }
  
    .settings-dropdown {
      padding: 5px;
    }
  
    .rss-filter {
      display: flex;
      flex-direction: column;
      align-items: flex-start; /* Align items to the left */
      width: 94%;
      gap: 10px;
    }
  
    /* Adjust rss-feed to show only one feed at a time */
    .rss-feed {
      display: flex; /* Use flex to ensure items take full height */
      flex-direction: column;
      overflow: hidden; /* Prevent horizontal scrolling */
      width: 100%; /* Ensure full width */
      padding: 0; /* Remove padding */
      margin: 0; /* Remove margin */
    }
  
    .rss-item_news h2 {
    font-size: 18px;
    margin: 5px 0;
    margin-bottom: 15px;
  }
  
    .rss-item_news {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Space out content */
    border: 1px solid #393939;
    padding: 10px;
    height: auto;
    border-radius: 5px;
    background: #151719;
    text-decoration: none;
    transition: border-color 0.3s;
    overflow: hidden;
  }
  
  .rss-item_news p {
    font-size: 13px;
    margin-top: 125px; /* Increase the margin to move the text further down */
    margin-bottom: 20px; /* Increase space below the paragraph if needed */
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: block;
    padding: 10px 0; /* Optional: Add some vertical padding */
    flex-grow: 1; /* Allow content to grow and push the meta section down */
  }
  
    /* Adjust image to fit within the screen */
    .rss-item_news img {
    width: 100%; /* Make sure the image takes the full width of the container */
    height: 250px; /* Keeps the aspect ratio */
    max-height: 250px; /* Increase the maximum height to make the image larger */
    object-fit: contain; /* Ensures the image covers the available space without distortion */
    margin-bottom: -75px; /* Adjust spacing below the image */
    border-radius: 5px; /* Optional: keep some rounded corners */
    margin-top: 45px;
  }
  
  
    /* Adjust the filter and settings controls */
    .filter-toggle,
    .filter-control {
      width: 100%; /* Make buttons adapt to the width */
      margin-bottom: 10px; /* Add spacing between buttons */
    }
  
    /* Adjust tag filter styling */
    .rss-tag-filter {
      width: 93%; /* Allow flexibility */
      margin-bottom: 7px;
      margin-right: 13px;
    }
  
    .changelog-box {
      width: 200px;
      font-size: 12px;
      bottom: 25px;
      right: 10px;
      padding: 10px;
    }

    
    .toggle-slider {
    position: absolute;
    cursor: pointer;
    top: -5px;
    left: -15px;
    right: 4px;
    bottom: 5px;
    background-color: #ccc; /* Default background color */
    transition: 0.4s; /* Smooth transition */
    border-radius: 24px; /* Rounded corners for the slider */
  }
  
    /* Adjust other necessary elements */
    .content {
      padding: 10px; /* Adjust padding for mobile */
      width: 100vw; /* Ensure it fills the viewport width */
      box-sizing: border-box; /* Include padding in the width calculation */
    }
  
    .search-bar {
      width: 94%; /* Make the search bar full width on mobile */
      margin-bottom: 7px;
      margin-right: 13px;
    }
  }
  
  </style>
  
