import { createRouter, createWebHistory } from 'vue-router';
import NewsFeed from '../src/components/NewsFeed.vue';
import YouTubeFeed from '../src/components/YouTubeFeed.vue';
import PatchNotes from '../src/components/PatchNotes.vue';

const routes = [
  { path: '/', component: NewsFeed },
  { path: '/youtube', component: YouTubeFeed },
  { path: '/patch-notes', component: PatchNotes },
  // Redirect specific paths with debugging
  {
    path: '/3ht93htt3.json',
    beforeEnter: (to, from, next) => {
      next('/');
    },
  },
  {
    path: '/ffe5yt4y5u5u.json',
    beforeEnter: (to, from, next) => {
      next('/');
    },
  },
  {
    path: '/wiuh28h34t93h49th39.json',
    beforeEnter: (to, from, next) => {
      next('/');
    },
  },
  // Catch-all route for undefined paths
  { path: '/:pathMatch(.*)*', redirect: '/' },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
